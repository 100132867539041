import React from "react"

function IconSvg({ size = "100", color = "#fff" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 470 512">
      <g id="cono" transform="translate(-21)">
        <path
          id="Path_226"
          data-name="Path 226"
          d="M485.967,419.417,269.271,8.01a15,15,0,0,0-26.542,0L26.033,419.417A33.461,33.461,0,0,0,21,437c0,24.094,25.4,43.236,75.49,56.9C139.292,505.571,195.941,512,256,512s116.708-6.429,159.51-18.1C465.6,480.236,491,461.094,491,437A33.461,33.461,0,0,0,485.967,419.417ZM256,47.188,434.425,385.936q-8.789-3.063-18.915-5.833c-40.634-11.082-93.748-17.433-150.41-18.049-.005.027-18.188.012-18.2,0-56.662.616-109.776,6.967-150.41,18.049q-10.14,2.766-18.915,5.833ZM407.617,464.955C367.314,475.946,313.469,482,256,482s-111.314-6.054-151.617-17.045C60.06,452.866,51,439.829,51,437s9.06-15.866,53.383-27.955C144.686,398.054,198.531,392,256,392s111.314,6.054,151.617,17.045C451.94,421.134,461,434.171,461,437S451.94,452.866,407.617,464.955Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default IconSvg
